import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import { Transaction, PublicKey, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, createCloseAccountInstruction, createBurnInstruction } from '@solana/spl-token';
import { useQuery } from '@tanstack/react-query';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Grid, Typography, Divider } from '@mui/joy';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';

const MAX_INSTRUCTIONS_PER_TRANSACTION = 50;
const MAX_TRANSACTION_SIZE = 10;
const MAIN_TOKEN_LIST_URL = 'https://tokens.jup.ag/tokens?tags=verified,strict';
const FALLBACK_TOKEN_LIST_URL = 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@latest/src/tokens/solana.tokenlist.json';
const USD_TOKEN_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';

const fetchTokenLists = async () => {
    const [mainResponse, fallbackResponse] = await Promise.all([
        axios.get(MAIN_TOKEN_LIST_URL),
        axios.get(FALLBACK_TOKEN_LIST_URL),
    ]);

    return {
        mainTokenList: mainResponse.data,
        fallbackTokenList: fallbackResponse.data.tokens,
    };
};

const fetchTokenPrices = async (mints) => {
    if (mints.length === 0) return { solPrices: {}, usdPrices: {} };

    const ids = mints.join(',');
    const solPriceUrl = `https://api.jup.ag/price/v2?ids=${ids}&vsToken=So11111111111111111111111111111111111111112`;
    const usdPriceUrl = `https://api.jup.ag/price/v2?ids=${ids}&vsToken=${USD_TOKEN_ADDRESS}`;
    const [solResponse, usdResponse] = await Promise.all([axios.get(solPriceUrl), axios.get(usdPriceUrl)]);

    const solPrices = {};
    const usdPrices = {};
    mints.forEach((mint) => {
        const solPriceData = solResponse.data?.data?.[mint]?.price;
        solPrices[mint] = solPriceData ? parseFloat(solPriceData) : 0;

        const usdPriceData = usdResponse.data?.data?.[mint]?.price;
        usdPrices[mint] = usdPriceData ? parseFloat(usdPriceData) : 0;
    });

    return { solPrices, usdPrices };
};

const TransactionHandler = () => {
    const { publicKey, connected, signAllTransactions } = useWallet();
    const { connection } = useConnection();

    const [transactions, setTransactions] = useState([]);
    const [burnableTokens, setBurnableTokens] = useState([]);
    const [emptyAccounts, setEmptyAccounts] = useState([]);
    const [selectedTokens, setSelectedTokens] = useState(new Set());
    const [transactionInfo, setTransactionInfo] = useState(null);
    const [error, setError] = useState(null);
    const [tokenPrices, setTokenPrices] = useState({ solPrices: {}, usdPrices: {} });

    const { data: tokenLists, isError: tokenListError } = useQuery({
        queryKey: ['tokenLists'],
        queryFn: fetchTokenLists,
        onError: () => setError("Failed to fetch token lists"),
    });

    const handleSelectAll = () => {
        const newSelected = new Set(burnableTokens.map(token => token.mint.toBase58()));
        setSelectedTokens(newSelected);
    };
    
    const handleDeselectAll = () => {
        setSelectedTokens(new Set());
    };

    const totalSelected = selectedTokens.size;
    const totalFee = (totalSelected * 0.002).toFixed(3);

    const fetchTokens = useCallback(async () => {
        if (publicKey && connected) {
            try {
                const response = await connection.getParsedTokenAccountsByOwner(publicKey, { programId: TOKEN_PROGRAM_ID });
                const accounts = response.value;
                const empty = [];
                const burnable = [];
                const mints = [];

                accounts.forEach(({ pubkey, account }) => {
                    const data = account.data.parsed.info;
                    const amount = data.tokenAmount.uiAmount;
                    const decimals = data.tokenAmount.decimals;

                    if (amount > 0) {
                        burnable.push({
                            mint: new PublicKey(data.mint),
                            pubkey: new PublicKey(pubkey),
                            amount,
                            decimals
                        });
                        mints.push(data.mint);
                    } else {
                        empty.push({
                            mint: new PublicKey(data.mint),
                            pubkey: new PublicKey(pubkey)
                        });
                    }
                });

                const { solPrices, usdPrices } = await fetchTokenPrices(mints);
                setTokenPrices({ solPrices, usdPrices });

                const tokensWithMetadata = burnable.map(token => {
                    const mintAddress = token.mint.toBase58();
                    const metadata = tokenLists?.mainTokenList.find(t => t.address === mintAddress) ||
                        tokenLists?.fallbackTokenList.find(t => t.address === mintAddress);

                    return {
                        ...token,
                        name: metadata?.name || 'Unknown Token',
                        symbol: metadata?.symbol || '',
                        logo: metadata?.logoURI || 'https://upload.wikimedia.org/wikipedia/commons/2/25/Icon-round-Question_mark.jpg',
                    };
                });

                setBurnableTokens(tokensWithMetadata);
                setTransactions([]);
                setTransactionInfo(null);
                setEmptyAccounts(empty);

                return tokensWithMetadata;
            } catch (err) {
                console.error("Error fetching tokens:", err);
                setError("Failed to fetch tokens");
                return [];
            }
        } else {
            return [];
        }
    }, [publicKey, connected, connection, tokenLists]);

    useQuery({
        queryKey: ['tokens', connected],
        queryFn: fetchTokens,
        enabled: connected && !!publicKey,
        onError: () => setError("Failed to fetch tokens"),
    });

    useEffect(() => {
        if (connected && publicKey) {
            fetchTokens();
        }
    }, [connected, publicKey, fetchTokens]);

    const handleTokenSelect = (token) => {
        const newSelected = new Set(selectedTokens);
        const tokenMint = token.mint.toBase58();

        if (newSelected.has(tokenMint)) {
            newSelected.delete(tokenMint);
        } else {
            newSelected.add(tokenMint);
        }
        setSelectedTokens(newSelected);
    };

    const simulateAndExecuteTransactions = async (transactions) => {
        try {
            const signedTransactions = await signAllTransactions(transactions);
            for (const signedTransaction of signedTransactions) {
                await connection.sendRawTransaction(signedTransaction.serialize());
            }
            setTransactionInfo("Transactions executed successfully");
        } catch (err) {
            console.error("Transaction execution error:", err);
            setError(err.message);
        }
    };

    const burnAndCloseAccounts = useCallback(async () => {
        if (!publicKey || !connected) {
            setError("Wallet not connected or missing public key.");
            return;
        }
    
        const burnTransactions = [];
        let currentTransaction = new Transaction();

        for (const token of burnableTokens) {
            if (!selectedTokens.has(token.mint.toBase58())) {
                continue;
            }

            if (!token.pubkey || !publicKey) {
                console.warn("Token account or public key missing:", token);
                continue;
            }

            try {
                // Convert amount to the smallest unit integer
                // eslint-disable-next-line no-undef
                const amountInSmallestUnit = BigInt(Math.floor(token.amount * (10 ** token.decimals)));

                const burnInstruction = createBurnInstruction(
                    token.pubkey,
                    token.mint,
                    publicKey,
                    amountInSmallestUnit,
                    [],
                    TOKEN_PROGRAM_ID
                );

                currentTransaction.add(burnInstruction);
    
                if (currentTransaction.instructions.length >= MAX_TRANSACTION_SIZE) {
                    burnTransactions.push(currentTransaction);
                    currentTransaction = new Transaction();
                }
            } catch (err) {
                console.error("Error creating burn instruction:", err);
                setError("Failed to create transaction instructions for burning tokens.");
                return;
            }
        }
    
        if (currentTransaction.instructions.length > 0) {
            burnTransactions.push(currentTransaction);
        }
    
        try {
            const { blockhash } = await connection.getLatestBlockhash();
            burnTransactions.forEach((tx) => {
                tx.recentBlockhash = blockhash;
                tx.feePayer = publicKey;
            });
    
            setTransactionInfo(`Simulating and executing ${burnTransactions.length} transactions for burning tokens...`);
            await simulateAndExecuteTransactions(burnTransactions);
        } catch (err) {
            console.error("Error executing transactions:", err);
            setError("Failed to execute transactions for burning selected tokens.");
            return;
        }

        const emptyAccountTransactions = [];
        currentTransaction = new Transaction();
        let totalServiceFee = 0;

        for (const token of emptyAccounts) {
            if (!selectedTokens.has(token.mint.toBase58())) {
                continue;
            }

            if (!token.pubkey || !publicKey) {
                console.warn("Token account or public key missing:", token);
                continue;
            }
    
            try {
                const closeInstruction = createCloseAccountInstruction(
                    token.pubkey,
                    publicKey,
                    publicKey,
                    TOKEN_PROGRAM_ID
                );

                currentTransaction.add(closeInstruction);
                totalServiceFee += 0.0002;
    
                if (currentTransaction.instructions.length >= MAX_TRANSACTION_SIZE) {
                    const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
                    const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
                    const transferInstruction = SystemProgram.transfer({
                        fromPubkey: publicKey,
                        toPubkey: feeReceiver,
                        lamports: totalServiceFeeLamports
                    });

                    currentTransaction.add(transferInstruction);
                    emptyAccountTransactions.push(currentTransaction);
                    currentTransaction = new Transaction();
                    totalServiceFee = 0;
                }
            } catch (err) {
                console.error("Error creating close account instruction:", err);
                setError("Failed to create transaction instructions.");
                return;
            }
        }
    
        if (currentTransaction.instructions.length > 0) {
            const feeReceiver = new PublicKey("HcLRAUCCWnbWbQcrLX1QDV38E6kYeopXSdcu1tvD4pMK");
            const totalServiceFeeLamports = Math.round(totalServiceFee * LAMPORTS_PER_SOL);
            const transferInstruction = SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: feeReceiver,
                lamports: totalServiceFeeLamports
            });

            currentTransaction.add(transferInstruction);
            emptyAccountTransactions.push(currentTransaction);
        }
    
        try {
            const { blockhash } = await connection.getLatestBlockhash();
            emptyAccountTransactions.forEach((tx) => {
                tx.recentBlockhash = blockhash;
                tx.feePayer = publicKey;
            });
    
            setTransactionInfo(`Simulating and executing ${emptyAccountTransactions.length} transactions for closing accounts...`);
            await simulateAndExecuteTransactions(emptyAccountTransactions);
        } catch (err) {
            console.error("Error executing transactions:", err);
            setError("Failed to execute transactions for closing selected accounts.");
        }
    }, [burnableTokens, emptyAccounts, publicKey, connected, connection, selectedTokens]);

    if (!connected) {
        return null;
    }

    return (
        <Box sx={{ bgcolor: '#121113', p: 4, borderRadius: 4, maxWidth: 1200, margin: 'auto', fontFamily: 'F37 Atlantic, sans-serif' }}>
            {transactionInfo && (
                <Typography sx={{ mt: 2, color: '#FFFFFF', fontFamily: 'F37 Atlantic, sans-serif' }}>{transactionInfo}</Typography>
            )}
            <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Button onClick={handleSelectAll} variant="contained" color="primary" sx={{ mr: 2, borderRadius: 20, bgcolor: '#4C2FF2', fontFamily: 'F37 Atlantic, sans-serif' }}>Select All Tokens</Button>
                <Button onClick={handleDeselectAll} variant="contained" color="neutral" sx={{ borderRadius: 20, bgcolor: 'grey', fontFamily: 'F37 Atlantic, sans-serif' }}>Deselect All Tokens</Button>
            </Box>
            <Box sx={{ bgcolor: '#121113', p: 4, borderRadius: 2, mb: 4 }}>
                <Typography variant="h4" sx={{ color: '#FFFFFF', mb: 2, textAlign: 'left', fontWeight: 'bold', fontFamily: 'F37 Atlantic, sans-serif' }}>Accounts</Typography>
                <Card sx={{ p: 2, mb: 4, bgcolor: '#1A1A1A', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#FFFFFF' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <WalletIcon sx={{ color: '#4C2FF2', fontSize: 50 }} />
                        <Box>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'white', fontFamily: 'F37 Atlantic, sans-serif' }}>Vacant Accounts</Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'F37 Atlantic, sans-serif' }}>Count: {selectedTokens.size}</Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'F37 Atlantic, sans-serif' }}>+{totalFee} SOL</Typography>
                        </Box>
                    </Box>
                    <Button variant="contained" color="primary" sx={{ bgcolor: '#512da8', borderRadius: 20, fontFamily: 'F37 Atlantic, sans-serif' }} onClick={burnAndCloseAccounts}>Claim & Close Selected</Button>
                </Card>

                <Typography variant="h4" sx={{ color: '#FFFFFF', mb: 2, textAlign: 'left', fontWeight: 'bold', fontFamily: 'F37 Atlantic, sans-serif' }}>Tokens</Typography>
                <Grid container spacing={3} justifyContent="center">
                    {burnableTokens.map((token) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={token.mint.toBase58()} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Card sx={{ width: 300, bgcolor: '#1A1A1A', borderRadius: 4, color: '#FFFFFF' }}>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={token.logo}
                                    title={token.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center', fontFamily: 'F37 Atlantic, sans-serif' }}>
                                        {token.name}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center', fontFamily: 'F37 Atlantic, sans-serif' }}>
                                        ~{(tokenPrices.usdPrices[token.mint.toBase58()] * token.amount || 0).toFixed(6)} USD
                                    </Typography>
                                    <Typography variant="body2" sx={{ mb: 1, textAlign: 'center', fontFamily: 'F37 Atlantic, sans-serif' }}>
                                        {token.amount.toFixed(2)} {token.symbol || 'tokens'}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button color='#4C2FF2' variant='outlined' size="large" onClick={() => handleTokenSelect(token)} 
                                        sx={{ fontFamily: 'F37 Atlantic, sans-serif', width: '100%', bgcolor: selectedTokens.has(token.mint.toBase58()) ? '#4C2FF2' : '#888', }}>
                                        {selectedTokens.has(token.mint.toBase58()) ? `+${(tokenPrices.solPrices[token.mint.toBase58()] * token.amount || 0).toFixed(6)} SOL` : `-${(tokenPrices.solPrices[token.mint.toBase58()] * token.amount || 0).toFixed(6)} SOL`}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Divider sx={{ my: 3, bgcolor: '#4C2FF2' }} />
                
                <Box sx={{ mt: 4, p: 2, bgcolor: '#1A1A1A', borderRadius: 4, color: '#FFFFFF', textAlign: 'left' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1, fontFamily: 'F37 Atlantic, sans-serif' }}>Items Selected: {totalSelected}</Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'F37 Atlantic, sans-serif' }}>Sol to be exchanged: {totalFee} SOL</Typography>
                </Box>
            </Box>
            {error && (<Typography color="error" sx={{ mt: 2, fontFamily: 'F37 Atlantic, sans-serif' }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default TransactionHandler;